import React, { useCallback, useState } from 'react'

import { AppBar, Button, Toolbar, Typography } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import Amplify, { Auth } from 'aws-amplify'
import { withAuthenticator } from 'aws-amplify-react'
import axios, { AxiosRequestConfig } from 'axios'

import config from './config'
import './App.css'

Amplify.configure({
  Auth: config[config.STAGE].Auth,
})

const isFile = (data: any): data is File =>
  data.size !== undefined && data.name !== undefined && data.type !== undefined

const isUploadToS3 = (config: AxiosRequestConfig): boolean =>
  config.method === 'put' && !!config.url && /\.s3\./.test(config.url) && isFile(config.data)

export const axiosRequestInterceptor = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const session = await Auth.currentSession()
  // s3へのPUTは Authorizationヘッダーを受け付けないらしい
  // https://stackoverflow.com/a/53031218
  if (isUploadToS3(config)) {
    delete config.headers.Authorization
    return config
  }

  if (session) {
    config.headers.Authorization = session.getIdToken().getJwtToken()
  }
  return config
}
axios.interceptors.request.use(axiosRequestInterceptor, (e) => Promise.reject(e))

const App: React.FC = () => {
  const [uploading, setUploading] = useState(false)

  const onDrop = useCallback(async (acceptedFiles) => {
    setUploading(true)

    await Promise.all(
      acceptedFiles.map(async (file: File) => {
        axios
          .post<{
            presignedUrl: string
            objectKey: string
            contentType: string
          }>(config[config.STAGE].endpoint + '/api/v1/generateUploadUrl', {
            folder: 'dropzone',
            filename: file.name,
          })
          .then((res) => {
            // axiosだとEdgeでS3へのアップロードができなかったのでfetch APIを使用
            fetch(res.data.presignedUrl, {
              method: 'PUT',
              body: file,
              headers: {
                'Content-Type': res.data.contentType,
              },
            }).then((response) => {
              if (config.STAGE === 'development') {
                console.log(response)
              }
              setUploading(false)
            })
          })
          .catch((error) => console.error(error.body))
      })
    )
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.zip' })

  const signOut = () => {
    Amplify.Auth.signOut()
    window.location.reload()
  }

  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            イラストメモリポータルサイト
          </Typography>
          <Button color="inherit" onClick={signOut}>
            ログアウト
          </Button>
        </Toolbar>
      </AppBar>
      {uploading ? (
        <div className="dropzone uploading">
          <br />
          アップロード中...
        </div>
      ) : (
        <div className="dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          <p>翻訳キットをアップロードしてください。</p>
          <ul>
            <li>原文言語: 英語</li>
            <li>訳文言語: ドイツ語、フランス語、スペイン語</li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default withAuthenticator(App)
